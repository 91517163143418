const utils = {
  extractApiError(e) {
    if (!e) {
      return {
        error: "Unknown error",
        hasFieldErrors: false,
        fieldErrors: {},
        status: null,
      };
    }

    if (e.response) {
      if (e.response.data && e.response.data.message) {
        let fieldErrors = {};
        if (e.response.data.errors) {
          fieldErrors = e.response.data.errors.reduce(function (map, error) {
            map[error["field"]] = error["reason"];
            return map;
          }, {});
        }
        return {
          error: e.response.data.message,
          hasFieldErrors: Object.keys(fieldErrors).length > 0,
          fieldErrors: fieldErrors,
          status: e.response.status,
        };
      }

      return {
        error: `${e}`,
        hasFieldErrors: false,
        fieldErrors: {},
        status: e.response.status,
      };
    }

    return {
      error: `${e}`,
      hasFieldErrors: false,
      fieldErrors: {},
      status: null,
    };
  },
};

export default utils;
