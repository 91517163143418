<template>
  <div class="narrow-view">
    <div class="options">
      <h1>Options</h1>
      <router-link class="option" to="/task-lists/new">Create a new task list</router-link>
      <router-link class="option" to="/task-lists/manage">Manage task lists</router-link>
      <router-link class="option" to="/help">Help, tips and tricks</router-link>
      <router-link v-if="user" class="option" to="/logout"
        >Log out <em>{{ user.username }}</em></router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "OptionsView",
  metaInfo: {
    title: "Options",
  },
  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/base.scss";

.options {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 1fr 1fr;

  h1 {
    grid-column: 1 / 3;
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
    color: $colour-link;
  }
}

.option {
  border: 1px solid $colour-light-grey;
  border-bottom-width: 2px;
  background: $colour-light-grey;
  padding: 15px 25px;
  border-radius: 3px;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;

  &:hover {
    background: white;
    border: 1px solid #999999;
    border-bottom-width: 2px;
  }
}
</style>
