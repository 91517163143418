import Vue from "vue";
import Vuex from "vuex";
import taskService from "@/services/task-service";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: null,
    taskListSummaries: [],
    activeTaskListId: null,
    tasks: [],
  },
  actions: {
    async loadTaskListSummaries({ commit }) {
      const summaries = await taskService.getTaskListSummaries();
      commit("setTaskListSummaries", summaries);
      return summaries;
    },

    async loadTasks({ commit, state }) {
      const tasks = await taskService.getTasksByTaskListId(state.activeTaskListId);
      commit("setTasks", tasks);
    },

    async updateTask({ commit, state }, props) {
      const task = props.task;
      const details = props.details;
      const taskAndTaskListSummary = await taskService.updateTask(state.activeTaskListId, task.id, details);
      commit("updateTask", taskAndTaskListSummary["task"]);
      commit("updateTaskListSummary", taskAndTaskListSummary["taskListSummary"]);
    },

    async createTask({ commit, state }, details) {
      const taskAndTaskListSummary = await taskService.createTask(state.activeTaskListId, details);
      commit("addTask", taskAndTaskListSummary["task"]);
      commit("updateTaskListSummary", taskAndTaskListSummary["taskListSummary"]);
    },

    async removeCompleted({ state, dispatch }) {
      const taskIds = state.tasks.filter((task) => task.completed).map((task) => task.id);
      await taskService.deleteTasks(state.activeTaskListId, taskIds);
      dispatch("loadTasks");
    },
  },
  mutations: {
    setTaskListSummaries(state, summaries) {
      state.taskListSummaries = summaries;
    },

    setActiveTaskListId(state, taskListId) {
      state.activeTaskListId = taskListId;
    },

    setTasks(state, tasks) {
      state.tasks = tasks;
    },

    clearTasks(state) {
      state.tasks = [];
    },

    addTask(state, task) {
      state.tasks.unshift(task);
    },

    updateTask(state, newTask) {
      const index = state.tasks.findIndex((oldTask) => oldTask.id === newTask.id);
      if (index >= 0) {
        const task = state.tasks[index];
        task.completed = newTask.completed;
        task.priority = newTask.priority;
        task.starred = newTask.starred;
        task.description = newTask.description;
      } else {
        console.log(`Expected to update tasks with task ${newTask.id}`);
      }
    },

    updateTaskListSummary(state, newSummary) {
      const index = state.taskListSummaries.findIndex((oldSummary) => oldSummary.id === newSummary.id);
      if (index >= 0) {
        const summary = state.taskListSummaries[index];
        summary.priorityCounts = newSummary.priorityCounts;
        summary.starCount = newSummary.starCount;
      } else {
        console.log(`Expected to update task list summary with task list ${newSummary.id}`);
      }
    },
  },
  getters: {
    loggedInUser: (state) => state.user,

    findTaskListSummaryById: (state) => (taskListId) => {
      return state.taskListSummaries.find((taskList) => taskList.id === taskListId);
    },

    tasks: (state) => {
      return state.tasks || [];
    },

    completedTaskCount: (state, getters) => {
      return getters.tasks.filter((task) => task.completed).length;
    },
  },
});

export default store;
