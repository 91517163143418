import apiClient from "@/services/api-client";
import store from "@/store";

export const PATH_LOGIN = "/api/login";
export const PATH_LOGOUT = "/api/logout";
export const PATH_CSRF_TOKEN = "/api/auth/csrf";

const LOCAL_STORAGE_KEY_USER = "user";

const authService = {
  async login(username, password) {
    this.clearLoggedInUser();
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);
    const config = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };
    const response = await apiClient.post(PATH_LOGIN, params, config);

    if (response.status === 200) {
      const user = response.data || { username: "unknown" };
      this.setLoggedInUser(user);
    }

    return response.data;
  },

  async logout() {
    localStorage.setItem(LOCAL_STORAGE_KEY_USER, null);
    const response = await apiClient.post(PATH_LOGOUT);
    return response.data;
  },

  async fetchCsrfToken() {
    // API client interceptors will stash the token
    await apiClient.get(PATH_CSRF_TOKEN);
  },

  setLoggedInUser(user) {
    localStorage.setItem(LOCAL_STORAGE_KEY_USER, JSON.stringify(user));
    store.state.user = user;
  },

  clearLoggedInUser() {
    localStorage.setItem(LOCAL_STORAGE_KEY_USER, null);
    store.state.user = null;
  },

  getLoggedInUser() {
    const fromLocalStorage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_USER));
    if (store.state.user !== fromLocalStorage) {
      store.state.user = fromLocalStorage;
    }
    return fromLocalStorage;
  },
};

export default authService;
