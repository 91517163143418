<template>
  <div class="header">
    <div class="left">
      <Logo />
      <router-link v-if="user" class="link" to="/task-lists">Tasks</router-link>
    </div>
    <div class="right">
      <router-link v-if="user" class="link" to="/options">Options</router-link>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";

export default {
  name: "Header",
  components: {
    Logo,
  },
  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/base.scss";

$padding-link: 20px;

.header {
  background: linear-gradient(to right, #1954a0, #1ab4c8);
  padding: 5px $main-margin-x;
}

.header {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}

.link {
  margin-left: $padding-link;
  white-space: nowrap;
  color: #ffffff;

  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: #ffffff;
  }

  &:active {
    color: #ffffff;
  }
}
</style>
