import Vue from "vue";
import VueRouter from "vue-router";
import ViewNotFound from "@/views/ViewNotFound";
import OptionsView from "@/views/OptionsView";
import HelpView from "@/views/HelpView";
import TaskListsView from "@/views/TaskListsView";
import TaskListView from "@/views/TaskListView";
import NewTaskListView from "@/views/NewTaskListView";
import ManageTaskListsView from "@/views/ManageTaskListsView";
import EditTaskListView from "@/views/EditTaskListView";
import DeleteTaskListView from "@/views/DeleteTaskListView";
import EditTaskView from "@/views/EditTaskView";
import LoginView from "@/views/LoginView";
import LogoutView from "@/views/LogoutView";
import authService from "@/services/auth-service";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "taskLists" },
  },
  {
    name: "taskLists",
    path: "/task-lists",
    component: TaskListsView,
  },
  {
    name: "newTaskList",
    path: "/task-lists/new",
    component: NewTaskListView,
  },
  {
    name: "manageTaskLists",
    path: "/task-lists/manage",
    component: ManageTaskListsView,
  },
  {
    name: "editTaskList",
    path: "/task-lists/:taskListId/edit",
    component: EditTaskListView,
    props(route) {
      const props = { ...route.params };
      // String => Number conversion
      props.taskListId = +props.taskListId;
      return props;
    },
  },
  {
    name: "deleteTaskList",
    path: "/task-lists/:taskListId/delete",
    component: DeleteTaskListView,
    props(route) {
      const props = { ...route.params };
      // String => Number conversion
      props.taskListId = +props.taskListId;
      return props;
    },
  },
  {
    name: "taskList",
    path: "/task-lists/:taskListId",
    component: TaskListView,
    props(route) {
      const props = { ...route.params };
      // String => Number conversion
      props.taskListId = +props.taskListId;
      return props;
    },
  },
  {
    name: "editTask",
    path: "/task-lists/:taskListId/tasks/:taskId",
    component: EditTaskView,
    props(route) {
      const props = { ...route.params };
      // String => Number conversion
      props.taskListId = +props.taskListId;
      props.taskId = +props.taskId;
      return props;
    },
  },
  {
    path: "/options",
    component: OptionsView,
  },
  {
    path: "/help",
    component: HelpView,
  },
  {
    path: "/login",
    component: LoginView,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/logout",
    component: LogoutView,
  },
  {
    name: "notFound",
    path: "*",
    component: ViewNotFound,
    meta: {
      allowAnonymous: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const user = authService.getLoggedInUser();

  if (to.matched.some((route) => route.meta.allowAnonymous)) {
    next();
  } else if (!user) {
    next({
      path: "/login",
    });
  } else {
    next();
  }
});

export default router;
