import apiClient from "@/services/api-client";

export const PRIORITY_1 = 1;
export const PRIORITY_2 = 2;
export const PRIORITY_3 = 3;
export const PRIORITY_4 = 4;
export const PRIORITY_5 = 5;

export const MIN_PRIORITY = PRIORITY_1;
export const MAX_PRIORITY = PRIORITY_5;

export const PRIORITIES = [PRIORITY_1, PRIORITY_2, PRIORITY_3, PRIORITY_4, PRIORITY_5];

export const TASK_LIST_TYPE_PRIORITY = "priority";
export const TASK_LIST_TYPE_STAR = "star";

export const DEFAULT_TASK_LIST_TYPE = TASK_LIST_TYPE_PRIORITY;

const API_PATH_TASK_LISTS = "/api/task-lists";

const taskService = {
  async getTaskLists() {
    const response = await apiClient.get(`${API_PATH_TASK_LISTS}`);
    return response.data["taskLists"];
  },

  async getTaskListSummaries() {
    const response = await apiClient.get(`${API_PATH_TASK_LISTS}/summaries`);
    return response.data["summaries"];
  },

  async createTaskList(name, position, type) {
    const details = { name: name, position: position, type: type, enabled: true };
    const response = await apiClient.post(API_PATH_TASK_LISTS, details);
    return response.data;
  },

  async updateTaskList(taskListId, details) {
    const response = await apiClient.patch(`${API_PATH_TASK_LISTS}/${taskListId}`, details);
    return response.data;
  },

  async getTaskListById(taskListId) {
    const response = await apiClient.get(`${API_PATH_TASK_LISTS}/${taskListId}`);
    return response.data;
  },

  async deleteTaskListById(taskListId) {
    await apiClient.delete(`${API_PATH_TASK_LISTS}/${taskListId}`);
  },

  async getTasksByTaskListId(taskListId) {
    const response = await apiClient.get(`${API_PATH_TASK_LISTS}/${taskListId}/tasks`);
    return response.data["tasks"];
  },

  async getTaskById(taskListId, taskId) {
    const response = await apiClient.get(`${API_PATH_TASK_LISTS}/${taskListId}/tasks/${taskId}`);
    return response.data;
  },

  async createTask(taskListId, task) {
    const response = await apiClient.post(`${API_PATH_TASK_LISTS}/${taskListId}/tasks`, task);
    return response.data;
  },

  async updateTask(taskListId, taskId, details) {
    const response = await apiClient.patch(`${API_PATH_TASK_LISTS}/${taskListId}/tasks/${taskId}`, details);
    return response.data;
  },

  async deleteTasks(taskListId, taskIds) {
    const params = {
      ids: taskIds.join(","),
    };
    const response = await apiClient.delete(`${API_PATH_TASK_LISTS}/${taskListId}/tasks`, { params: params });
    return response.data;
  },
};

export default taskService;
