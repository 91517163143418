<template>
  <div class="narrow-view">
    <h1>Delete task list</h1>
    <div v-if="notice" class="notice-box">{{ notice }}</div>
    <div v-if="error" class="error-box">{{ error }}</div>
    <div v-if="taskList">
      <form @submit.prevent="submit">
        <div class="group">
          <div>
            Delete task list <strong>{{ taskList.name }}</strong
            >?
          </div>
        </div>
        <div v-if="needsConfirmation" class="group">
          <input class="confirm-delete" type="checkbox" v-model="deleteConfirmed" />
          <span>Delete task list with active tasks</span>
        </div>
        <div class="group">
          <button class="submit" type="submit" :disabled="requestActive || (needsConfirmation && !deleteConfirmed)">
            Delete
          </button>
          <router-link :to="{ name: 'manageTaskLists' }" class="pad-left">Cancel</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import taskService from "@/services/task-service";
import apiUtils from "@/services/api-utils";

export default {
  name: "DeleteTaskListView",
  props: {
    taskListId: Number,
  },
  metaInfo: {
    title: "Delete task list",
  },
  data() {
    return {
      taskList: null,
      needsConfirmation: false,
      deleteConfirmed: false,
      notice: "",
      error: "",
      requestActive: false,
    };
  },
  methods: {
    resetErrors() {
      this.notice = "";
      this.error = "";
    },
    async submit() {
      this.resetErrors();
      if (!this.requestActive) {
        this.requestActive = true;
        try {
          await taskService.deleteTaskListById(this.taskList.id);
          await this.$router.push("/task-lists/manage");
        } catch (e) {
          const apiError = apiUtils.extractApiError(e);
          if (apiError.hasFieldErrors) {
            this.fieldErrors = apiError.fieldErrors;
          } else {
            this.error = `Unable to delete task list: ${apiError.error}`;
          }
        } finally {
          this.requestActive = false;
        }
      }
    },
  },
  async created() {
    if (!Number.isInteger(this.taskListId)) {
      this.error = "Invalid task list";
      return;
    }
    try {
      this.taskList = await taskService.getTaskListById(this.taskListId);
      const tasks = await taskService.getTasksByTaskListId(this.taskList.id);
      this.needsConfirmation = tasks.filter((task) => !task.completed).length > 0;
    } catch (e) {
      const apiError = apiUtils.extractApiError(e);
      this.error = apiError.error;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/base.scss";

form {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 20px;
  width: 100%;
}

.group {
  grid-column: 1 / 2;

  div {
    margin-bottom: 10px;
  }
}

.confirm-delete {
  margin-right: 15px;
}
</style>
