<template>
  <div class="narrow-view">
    <h1>New task list</h1>
    <div v-if="error" class="error-box">{{ error }}</div>
    <form @submit.prevent="submit">
      <div class="group">
        <div>
          <label for="name">Name</label>
        </div>
        <div>
          <input
            id="name"
            ref="name-input"
            type="text"
            v-model.trim="name"
            v-bind:class="{ 'input-error': fieldErrors.name }"
            class="text-input-full-width"
            placeholder="Task list"
            autocomplete="off"
          />
        </div>
        <div v-if="fieldErrors.name" class="field-error">{{ fieldErrors.name }}</div>
      </div>
      <div class="group">
        <div>
          <label for="position">Position</label>
        </div>
        <div>
          <input
            id="position"
            type="text"
            v-model.number="position"
            v-bind:class="{ 'input-error': fieldErrors.position }"
            class="text-input-short"
            autocomplete="off"
          />
        </div>
        <div v-if="fieldErrors.position" class="field-error">{{ fieldErrors.position }}</div>
      </div>
      <div class="group">
        <div>
          <label for="type">Type</label>
        </div>
        <div>
          <select id="type" v-model="type" v-bind:class="{ 'input-error': fieldErrors.type }">
            <option :value="TASK_LIST_TYPE_PRIORITY">Priority</option>
            <option :value="TASK_LIST_TYPE_STAR">Star</option>
          </select>
        </div>
        <div v-if="fieldErrors.type" class="field-error">{{ fieldErrors.type }}</div>
      </div>
      <div class="group">
        <button class="submit" type="submit" :disabled="requestActive">Create</button>
        <router-link :to="{ name: 'manageTaskLists' }" class="pad-left">Cancel</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import taskService, {
  DEFAULT_TASK_LIST_TYPE,
  TASK_LIST_TYPE_PRIORITY,
  TASK_LIST_TYPE_STAR,
} from "@/services/task-service";
import apiUtils from "@/services/api-utils";

const DEFAULT_POSITION = 1000;

export default {
  name: "NewTaskListView",
  metaInfo: {
    title: "New task list",
  },
  data() {
    return {
      name: "",
      position: DEFAULT_POSITION,
      type: DEFAULT_TASK_LIST_TYPE,
      error: "",
      fieldErrors: {},
      requestActive: false,
      TASK_LIST_TYPE_PRIORITY,
      TASK_LIST_TYPE_STAR,
    };
  },
  methods: {
    resetErrors() {
      this.error = "";
      this.fieldErrors = {};
    },
    async submit() {
      this.resetErrors();
      if (!this.requestActive) {
        this.requestActive = true;
        try {
          const taskList = await taskService.createTaskList(this.name, this.position, this.type);
          await this.$router.push({ name: "taskList", params: { taskListId: "" + taskList.id } });
        } catch (e) {
          const apiError = apiUtils.extractApiError(e);
          if (apiError.hasFieldErrors) {
            this.fieldErrors = apiError.fieldErrors;
          } else {
            this.error = `Unable to create task list: ${apiError.error}`;
          }
        } finally {
          this.requestActive = false;
        }
      }
    },
  },
  mounted() {
    this.resetErrors();
    this.$refs["name-input"].focus();
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/base.scss";

form {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 20px;
  width: 100%;
}

.group {
  grid-column: 1 / 2;

  div {
    margin-bottom: 10px;
  }
}
</style>
