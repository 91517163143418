<template>
  <div class="narrow-view">
    <h1>Edit task list</h1>
    <div v-if="notice" class="notice-box">{{ notice }}</div>
    <div v-if="error" class="error-box">{{ error }}</div>
    <div v-if="taskList">
      <form @submit.prevent="submit">
        <div class="group">
          <div>
            <label for="name">Name</label>
          </div>
          <div>
            <input
              id="name"
              type="text"
              v-model.trim="taskList.name"
              v-bind:class="{ 'input-error': fieldErrors.name }"
              class="text-input-full-width"
              placeholder="Task list"
              autocomplete="off"
            />
          </div>
          <div v-if="fieldErrors.name" class="field-error">{{ fieldErrors.name }}</div>
        </div>
        <div class="group">
          <div>
            <label for="position">Position</label>
          </div>
          <div>
            <input
              id="position"
              type="text"
              v-model.number="taskList.position"
              v-bind:class="{ 'input-error': fieldErrors.position }"
              class="text-input-short"
              autocomplete="off"
            />
          </div>
          <div v-if="fieldErrors.position" class="field-error">{{ fieldErrors.position }}</div>
        </div>
        <div class="group">
          <div>
            <label for="type">Type</label>
          </div>
          <div>
            <select id="type" v-model="taskList.type" v-bind:class="{ 'input-error': fieldErrors.type }">
              <option :value="TASK_LIST_TYPE_PRIORITY">Priority</option>
              <option :value="TASK_LIST_TYPE_STAR">Star</option>
            </select>
          </div>
          <div v-if="fieldErrors.type" class="field-error">{{ fieldErrors.type }}</div>
        </div>
        <div class="group">
          <div>
            <label for="enabled">Enabled?</label>
          </div>
          <div>
            <select id="enabled" v-model="taskList.enabled" v-bind:class="{ 'input-error': fieldErrors.enabled }">
              <option :value="true">Yes</option>
              <option :value="false">No</option>
            </select>
          </div>
          <div v-if="fieldErrors.enabled" class="field-error">{{ fieldErrors.enabled }}</div>
        </div>
        <div class="group">
          <button class="submit" type="submit" :disabled="requestActive">Update</button>
          <router-link :to="{ name: 'taskList', params: { taskListId: this.taskListId } }" class="pad-left"
            >View tasks</router-link
          >
          <router-link :to="{ name: 'manageTaskLists' }" class="pad-left">Back to all task lists</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import taskService, { TASK_LIST_TYPE_PRIORITY, TASK_LIST_TYPE_STAR } from "@/services/task-service";
import apiUtils from "@/services/api-utils";

export default {
  name: "EditTaskListView",
  props: {
    taskListId: Number,
  },
  metaInfo: {
    title: "Edit task list",
  },
  data() {
    return {
      taskList: null,
      notice: "",
      error: "",
      fieldErrors: {},
      requestActive: false,
      TASK_LIST_TYPE_PRIORITY,
      TASK_LIST_TYPE_STAR,
    };
  },
  methods: {
    resetErrors() {
      this.error = "";
      this.fieldErrors = {};
      this.notice = "";
    },
    async submit() {
      this.resetErrors();
      if (!this.requestActive) {
        this.requestActive = true;
        try {
          const details = {
            name: this.taskList.name,
            position: this.taskList.position,
            type: this.taskList.type,
            enabled: this.taskList.enabled,
          };
          this.taskList = await taskService.updateTaskList(this.taskList.id, details);
          this.notice = "Task list updated";
        } catch (e) {
          const apiError = apiUtils.extractApiError(e);
          if (apiError.hasFieldErrors) {
            this.fieldErrors = apiError.fieldErrors;
          } else {
            this.error = `Unable to create task list: ${apiError.error}`;
          }
        } finally {
          this.requestActive = false;
        }
      }
    },
  },
  async created() {
    if (!Number.isInteger(this.taskListId)) {
      this.error = "Invalid task list";
      return;
    }
    try {
      this.taskList = await taskService.getTaskListById(this.taskListId);
    } catch (e) {
      const apiError = apiUtils.extractApiError(e);
      this.error = apiError.error;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/base.scss";

form {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 20px;
  width: 100%;
}

.group {
  grid-column: 1 / 2;

  div {
    margin-bottom: 10px;
  }
}
</style>
