<template>
  <tr
    class="task"
    :class="{ hover: hover, 'is-completed': task.completed }"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <td class="completed-col" @click.prevent="toggleComplete">
      <input class="checkbox" type="checkbox" v-model="task.completed" @click.prevent="toggleComplete" />
    </td>
    <td v-if="taskListType === 'star'" class="priority-col">
      <div
        class="star-icon"
        :class="[task.starred ? 'star-active' : 'star-inactive', task.completed ? 'completed' : '']"
        @click.prevent="toggleStarred"
      ></div>
    </td>
    <td v-else class="priority-col">
      <div class="priority-icon" :class="[task.completed ? 'completed' : 'priority-' + task.priority]">
        {{ task.priority }}
      </div>
    </td>
    <td class="description-col" v-linkified>{{ task.description }}</td>
    <td class="options-col">
      <router-link :to="{ name: 'editTask', params: { taskListId: task.taskListId, taskId: task.id } }"
        >Edit</router-link
      >
    </td>
  </tr>
</template>

<script>
export default {
  name: "Task",
  props: {
    task: Object,
    taskListType: String,
  },
  data() {
    return {
      hover: false,
      requestActive: false,
    };
  },
  methods: {
    async toggleComplete() {
      if (!this.requestActive) {
        this.requestActive = true;
        try {
          const details = {
            completed: !this.task.completed,
          };
          await this.$store.dispatch("updateTask", { task: this.task, details: details });
        } catch (e) {
          // Show an error?
        } finally {
          this.requestActive = false;
        }
      }
    },
    async toggleStarred() {
      if (!this.requestActive) {
        this.requestActive = true;
        try {
          const details = {
            starred: !this.task.starred,
          };
          await this.$store.dispatch("updateTask", { task: this.task, details: details });
        } catch (e) {
          // Show an error?
        } finally {
          this.requestActive = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/base.scss";

$padding: 6px;

.task {
  vertical-align: middle;

  td {
    padding: $padding $padding * 2;
    border-top: 1px solid $colour-bg;

    &.completed-col {
      text-align: center;
      max-width: 2%;
      cursor: pointer;

      .checkbox {
        cursor: pointer;
        margin: 0;
        padding: 0;
      }
    }

    &.priority-col {
      text-align: center;
      max-width: 2%;
      padding: $padding;
    }

    &.description-col {
      text-align: left;
      width: 94%;
    }

    &.options-col {
      text-align: right;
      width: 2%;
    }
  }
}

.is-completed {
  .description-col {
    color: $colour-task-completed !important;
    text-decoration: line-through;
    font-style: italic;
  }
}

.hover {
  td {
    background: $colour-hover-bg !important;
  }
}

.priority-icon {
  display: flex;
  border-radius: 20px;
  margin: 1px;
  padding: 0;
  height: 18px;
  width: 18px;
  border-width: 1px;
  border-style: solid;
  border-color: #aaaaaa;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  font-size: 90%;

  &.completed {
    border-color: lighten($colour-task-completed, 30%) !important;
    color: #ffffff !important;
    background-color: lighten($colour-task-completed, 30%) !important;
  }

  &.priority-1 {
    border-color: $priority-1-border !important;
    color: $priority-1-fg !important;
    background-color: $priority-1-bg !important;
  }

  &.priority-2 {
    border-color: $priority-2-border !important;
    color: $priority-2-fg !important;
    background-color: $priority-2-bg !important;
  }

  &.priority-3 {
    border-color: $priority-3-border !important;
    color: $priority-3-fg !important;
    background-color: $priority-3-bg !important;
  }

  &.priority-4 {
    border-color: $priority-4-border !important;
    color: $priority-4-fg !important;
    background-color: $priority-4-bg !important;
  }

  &.priority-5 {
    border-color: $priority-5-border !important;
    color: $priority-5-fg !important;
    background-color: $priority-5-bg !important;
  }
}

.star-icon {
  display: flex;
  border-radius: 20px;
  margin: 1px;
  padding: 0;
  height: 18px;
  width: 18px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.completed {
    color: lighten($colour-task-completed, 30%) !important;
  }
}
</style>
<!-- Global styles to support the vue-linkiy module and how it applies classes -->
<style lang="scss">
@import "../styles/base.scss";

.linkified {
  color: $colour-link;
}

.is-completed .linkified {
  color: $colour-task-completed;
  text-decoration: none;
}
</style>
