<template>
  <div class="menu">
    <TaskListMenuItem
      v-for="taskListSummary in taskListSummaries"
      :key="taskListSummary.id"
      :id="taskListSummary.id"
      :name="taskListSummary.name"
      :type="taskListSummary.type"
      :priority-counts="taskListSummary.priorityCounts"
      :star-count="taskListSummary.starCount"
    />
  </div>
</template>

<script>
import TaskListMenuItem from "@/components/TaskListMenuItem";

export default {
  name: "TaskListMenu",
  components: {
    TaskListMenuItem,
  },
  computed: {
    taskListSummaries() {
      return this.$store.state.taskListSummaries;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/base.scss";

.menu {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: left;
  margin: 0;
  padding: 15px 0 0 0;
}
</style>
