<template>
  <div>
    <TaskListMenu />
    <div v-if="error" class="error-box">{{ error }}</div>
    <div class="loading" v-if="loading">Loading tasks...</div>
    <div v-if="loaded">
      <div v-if="taskList">
        <QuickAddTask :task-list="taskList" />
        <TaskTable :task-list="taskList" />
      </div>
      <div v-else class="not-found">Task list not found!</div>
    </div>
  </div>
</template>

<script>
import TaskListMenu from "@/components/TaskListMenu";
import QuickAddTask from "@/components/QuickAddTask";
import TaskTable from "@/components/TaskTable";
import apiUtils from "@/services/api-utils";

export default {
  name: "TaskListView",
  components: { TaskTable, TaskListMenu, QuickAddTask },
  metaInfo() {
    return {
      title: this.title,
    };
  },
  props: {
    taskListId: Number,
  },
  data() {
    return {
      taskList: null,
      title: "Task list",
      loading: true,
      loaded: false,
      error: null,
    };
  },
  created() {
    this.init();
  },
  watch: {
    $route: "init",
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        this.loaded = false;
        this.title = "Task list";
        this.$store.commit("clearTasks");
        await this.$store.dispatch("loadTaskListSummaries");
        const taskList = this.$store.getters.findTaskListSummaryById(this.taskListId);

        if (taskList) {
          this.taskList = taskList;
          this.title = taskList.name;
          this.$store.commit("setActiveTaskListId", taskList.id);
          await this.$store.dispatch("loadTasks");
        }

        this.loaded = true;
        this.error = null;
      } catch (e) {
        const apiError = apiUtils.extractApiError(e);
        this.error = "Unable to load task lists: " + apiError.error;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/base.scss";

.error-box {
  margin-top: 20px;
}
</style>
