<template>
  <div class="narrow-view">
    <h1>Edit task</h1>
    <div v-if="error" class="error-box">{{ error }}</div>
    <div v-if="task">
      <form @submit.prevent="submit">
        <div class="group-full">
          <div>
            <label for="name">Description</label>
          </div>
          <div>
            <textarea
              id="name"
              v-model.trim="task.description"
              v-bind:class="{ 'input-error': fieldErrors.description }"
              class="text-input-full-width"
              placeholder="Task"
              autocomplete="off"
            ></textarea>
          </div>
          <div v-if="fieldErrors.description" class="field-error">{{ fieldErrors.description }}</div>
        </div>
        <div class="group-left">
          <div><label for="taskList">Task list</label></div>
          <div>
            <select id="taskList" v-model="task.taskListId" v-bind:class="{ 'input-error': fieldErrors.taskList }">
              <option v-for="taskList in taskLists" :key="taskList.id" :value="taskList.id">{{ taskList.name }}</option>
            </select>
          </div>
          <div v-if="fieldErrors.taskList" class="field-error">{{ fieldErrors.taskList }}</div>
        </div>
        <div class="group-right">
          <div>
            <label for="priority">Priority</label>
          </div>
          <div>
            <select id="priority" v-model="task.priority" v-bind:class="{ 'input-error': fieldErrors.priority }">
              <option v-for="priority in priorities" :key="priority">{{ priority }}</option>
            </select>
          </div>
          <div v-if="fieldErrors.priority" class="field-error">{{ fieldErrors.priority }}</div>
        </div>
        <div class="group-left">
          <div>
            <label for="starred">Starred?</label>
          </div>
          <div>
            <select id="starred" v-model="task.starred" v-bind:class="{ 'input-error': fieldErrors.starred }">
              <option :value="true">Yes</option>
              <option :value="false">No</option>
            </select>
          </div>
          <div v-if="fieldErrors.starred" class="field-error">{{ fieldErrors.starred }}</div>
        </div>
        <div class="group-right">
          <div>
            <label for="completed">Completed?</label>
          </div>
          <div>
            <select id="completed" v-model="task.completed" v-bind:class="{ 'input-error': fieldErrors.completed }">
              <option :value="true">Yes</option>
              <option :value="false">No</option>
            </select>
          </div>
          <div v-if="fieldErrors.completed" class="field-error">{{ fieldErrors.completed }}</div>
        </div>
        <div class="group-full">
          <button class="submit" type="submit" :disabled="requestActive">Update</button>
          <router-link :to="{ name: 'taskList', params: { taskListId: this.taskListId } }" class="pad-left"
            >Cancel</router-link
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { PRIORITIES, TASK_LIST_TYPE_PRIORITY, TASK_LIST_TYPE_STAR } from "@/services/task-service";
import taskService from "@/services/task-service";
import apiUtils from "@/services/api-utils";

export default {
  name: "EditTaskView",
  props: {
    taskListId: Number,
    taskId: Number,
  },
  metaInfo: {
    title: "Edit task",
  },
  data() {
    return {
      task: null,
      error: "",
      fieldErrors: {},
      requestActive: false,
      taskLists: [],
      TASK_LIST_TYPE_PRIORITY: TASK_LIST_TYPE_PRIORITY,
      TASK_LIST_TYPE_STAR: TASK_LIST_TYPE_STAR,
    };
  },
  computed: {
    priorities() {
      return PRIORITIES;
    },
  },
  methods: {
    resetErrors() {
      this.error = "";
      this.fieldErrors = {};
    },
    async submit() {
      this.resetErrors();
      if (!this.requestActive) {
        this.requestActive = true;
        try {
          const details = {
            description: this.task.description,
            priority: this.task.priority,
            starred: this.task.starred,
            completed: this.task.completed,
            taskListId: this.task.taskListId,
          };
          const updatedTask = await taskService.updateTask(this.taskListId, this.taskId, details);
          await this.$router.push({ name: "taskList", params: { taskListId: "" + updatedTask.task.taskListId } });
        } catch (e) {
          const apiError = apiUtils.extractApiError(e);
          if (apiError.hasFieldErrors) {
            this.fieldErrors = apiError.fieldErrors;
          } else {
            this.error = `Unable to update task: ${apiError.error}`;
          }
        } finally {
          this.requestActive = false;
        }
      }
    },
  },
  async created() {
    if (!Number.isInteger(this.taskListId)) {
      this.error = "Invalid task list";
      return;
    }
    if (!Number.isInteger(this.taskId)) {
      this.error = "Invalid task";
      return;
    }

    try {
      this.taskLists = await this.$store.dispatch("loadTaskListSummaries");
      this.task = await taskService.getTaskById(this.taskListId, this.taskId);
    } catch (e) {
      const apiError = apiUtils.extractApiError(e);
      this.error = apiError.error;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/base.scss";

form {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  width: 100%;
}

.group-full {
  grid-column: 1 / 3;

  div {
    margin-bottom: 10px;
  }
}

.group-left {
  grid-column: 1 / 2;

  div {
    margin-bottom: 10px;
  }
}

.group-right {
  grid-column: 2 / 3;

  div {
    margin-bottom: 10px;
  }
}

textarea {
  width: 100%;
  height: 100px;
}
</style>
