<template>
  <div class="narrow-view">
    <h1>Not found</h1>
    <p>There's nothing here!</p>
  </div>
</template>

<script>
export default {
  name: "LoginView",
  metaInfo: {
    title: "Not found",
  },
};
</script>
