<template>
  <div class="narrow-view">
    <h1>Help</h1>
    <h3>Creating tasks</h3>
    <p>
      When adding a task using the task list "quick add" bar, you can set the new task's priority using a magic
      description.
    </p>
    <pre>
      <i>&lt;priority&gt;</i> - <i>&lt;description&gt;</i>
    </pre>
    <p>Other symbols are supported, and whitespace is optional. For example:</p>
    <pre>
      4 - buy bananas
      1.do the thing
      5) not very important!
      2: seek help
    </pre>
    <p>You can prevent this behaviour using the pattern:</p>
    <pre>
      \<i>&lt;description&gt;</i>
    </pre>
    <p>The prefix will be stripped. For example:</p>
    <pre>
      \2 low for zero
      \ 4 things to get
    </pre>
    <p>For star lists, the symbol <code>*</code> can be used to set a new task's star:</p>
    <pre>
      * starred
      *also starred
    </pre>
  </div>
</template>

<script>
export default {
  name: "HelpView",
  metaInfo: {
    title: "Help",
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/base.scss";
</style>
