<template>
  <router-link
    :to="{ name: 'taskList', params: { taskListId: id } }"
    class="item"
    :class="{ active: isActive, empty: isEmpty }"
  >
    <span class="name">{{ name }}</span>
    <template v-if="type === 'priority'">
      <span v-if="priorityOneCount > 0" class="priority-count"
        ><span class="priority-1">{{ priorityOneCount }}</span></span
      >
      <span v-if="priorityTwoCount > 0" class="priority-count"
        ><span class="priority-2">{{ priorityTwoCount }}</span></span
      >
    </template>
    <span v-if="type === 'star' && starCount > 0" class="star-active"></span>
  </router-link>
</template>

<script>
import { PRIORITY_2, PRIORITY_1 } from "@/services/task-service";

export default {
  name: "TaskListMenuItem",
  props: {
    id: Number,
    name: String,
    type: String,
    priorityCounts: Object,
    starCount: Number,
  },
  computed: {
    isActive() {
      return this.id === this.$store.state.activeTaskListId;
    },
    priorityOneCount() {
      return this.priorityCounts[PRIORITY_1] || 0;
    },
    priorityTwoCount() {
      return this.priorityCounts[PRIORITY_2] || 0;
    },
    taskCount() {
      return Object.values(this.priorityCounts || {}).reduce((total, next) => total + next, 0);
    },
    isEmpty() {
      return this.taskCount === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/base.scss";

.item {
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  margin: 0 10px 10px 0;
  padding: 5px 5px;
  border-radius: 3px;
  color: #393939;
  cursor: pointer;
  text-decoration: none !important;
  background: $colour-light-grey;
  border: 1px solid $colour-light-grey;
  height: 20px;

  &.active {
    border: 1px solid #4f4f4f;
    background: white;
    color: #111111;
  }

  &.empty {
    font-style: italic;
    color: #555555;
  }

  .name {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.priority-count {
  margin-right: 5px;
  display: inline-block;

  span {
    display: flex;
    border-radius: 30px;
    margin: 0;
    padding: 1px;
    height: 15px;
    width: 15px;
    border-width: 1px;
    border-style: solid;
    border-color: #aaaaaa;
    background-color: #ffffff;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 80%;
  }

  .priority-1 {
    border-color: $priority-1-border !important;
    color: $priority-1-fg !important;
    background-color: $priority-1-bg !important;
  }

  .priority-2 {
    border-color: $priority-2-border !important;
    color: $priority-2-fg !important;
    background-color: $priority-2-bg !important;
  }
}
</style>
