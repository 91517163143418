<template>
  <form @submit.prevent="submit">
    <div class="quick-add">
      <div class="description">
        <input
          id="quick-add-task-description"
          ref="quick-add-task-description"
          type="text"
          name="description"
          v-model.trim="description"
          class="description-text-input"
          autocomplete="off"
        />
      </div>
      <div v-if="taskList.type === TASK_LIST_TYPE_PRIORITY" class="priority">
        <select v-model="priority">
          <option v-for="priority in priorities" :key="priority">{{ priority }}</option>
        </select>
      </div>
      <div v-if="taskList.type === TASK_LIST_TYPE_STAR" class="star">
        <select v-model="starred">
          <option :value="true">★</option>
          <option :value="false">☆</option>
        </select>
      </div>
      <div class="submit">
        <button class="submit add-button" type="submit" :disabled="requestActive">Add</button>
      </div>
    </div>
  </form>
</template>

<script>
import {
  MAX_PRIORITY,
  MIN_PRIORITY,
  PRIORITIES,
  PRIORITY_3,
  TASK_LIST_TYPE_PRIORITY,
  TASK_LIST_TYPE_STAR,
} from "@/services/task-service";

const DEFAULT_PRIORITY = PRIORITY_3;

// "\2 days to go" => "2 days to go"
const IGNORE_MAGIC_DESCRIPTION_REGEX = new RegExp("^\\\\\\s*");

// "2 - do the thing" -> "do the thing", priority 2
const MAGIC_PRIORITY_DESCRIPTION_REGEX = new RegExp(
  `^\\s*(?<priority>[${MIN_PRIORITY}-${MAX_PRIORITY}])\\s*[-.,: ;/\\]>)]\\s*(?<description>.+)$`
);

// "* do the thing" -> "do the thing", starred
const MAGIC_STARRED_DESCRIPTION_REGEX = new RegExp(`^\\s*\\*\\s*(?<description>.+)$`);

export default {
  name: "QuickAddTask",
  props: {
    taskList: Object,
  },
  data() {
    return {
      description: "",
      priority: DEFAULT_PRIORITY,
      starred: false,
      requestActive: false,
      TASK_LIST_TYPE_PRIORITY,
      TASK_LIST_TYPE_STAR,
    };
  },
  computed: {
    priorities() {
      return PRIORITIES;
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    $route: "init",
  },
  methods: {
    reset() {
      this.description = "";
      this.priority = DEFAULT_PRIORITY;
      this.starred = false;
      this.$refs["quick-add-task-description"].focus();
    },
    canSubmit() {
      return this.description && this.description.length > 0;
    },
    async submit() {
      if (!this.requestActive && this.canSubmit()) {
        let description = this.description;
        let priority = this.priority;
        let starred = this.starred;
        if (this.taskList.type === TASK_LIST_TYPE_PRIORITY) {
          [description, priority] = this.decodePriorityDescription(description, priority);
        } else if (this.taskList.type === TASK_LIST_TYPE_STAR) {
          [description, starred] = this.decodeStarredDescription(description, starred);
        }
        this.requestActive = true;
        try {
          const details = {
            description,
            priority,
            starred,
            completed: false,
          };
          await this.$store.dispatch("createTask", details);
          this.reset();
        } catch (e) {
          // Show an error?
        } finally {
          this.requestActive = false;
        }
      }
    },
    init() {
      this.reset();
    },
    decodePriorityDescription(description, priority) {
      let newDescription = description;
      let newPriority = priority;

      if (description) {
        let match = description.match(IGNORE_MAGIC_DESCRIPTION_REGEX);

        if (match) {
          newDescription = newDescription.replace(IGNORE_MAGIC_DESCRIPTION_REGEX, "");
        } else {
          match = description.match(MAGIC_PRIORITY_DESCRIPTION_REGEX);
          if (match) {
            newDescription = match.groups.description;
            newPriority = match.groups.priority;
          }
        }
      }

      return [newDescription, newPriority];
    },
    decodeStarredDescription(description, starred) {
      let newDescription = description;
      let newStarred = starred;

      if (description) {
        let match = description.match(IGNORE_MAGIC_DESCRIPTION_REGEX);

        if (match) {
          newDescription = newDescription.replace(IGNORE_MAGIC_DESCRIPTION_REGEX, "");
        } else {
          match = description.match(MAGIC_STARRED_DESCRIPTION_REGEX);
          if (match) {
            newDescription = match.groups.description;
            newStarred = true;
          }
        }
      }

      return [newDescription, newStarred];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/base.scss";

$height: 34px;

.quick-add {
  margin-top: 10px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: auto minmax(min-content, 2%) minmax(min-content, 2%);
  grid-gap: 10px;
}

.description {
  grid-column: 1 / 2;
  align-self: center;

  .description-text-input {
    padding: 5px;
    width: 100%;
    height: $height !important;
  }
}

.priority {
  grid-column: 2 / 3;
  align-self: center;
  text-align: center;

  select {
    height: $height;
    padding: 1px 8px !important;
  }
}

.submit {
  grid-column: 3 / 4;
  align-self: center;
  text-align: right;
  height: $height;
}

.description-text-input {
  background: $colour-light-grey;
  border: 3px solid $colour-light-grey;
  border-radius: 3px;
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
}

.add-button {
  padding: 1px 12px !important;
}

.star {
  font-size: 90%;
}
</style>
