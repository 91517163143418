<template>
  <div id="app">
    <Header />
    <div id="main">
      <router-view />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  name: "App",
  components: {
    Header,
  },
  metaInfo: {
    title: "Bluebook",
    titleTemplate: "%s | Bluebook",
  },
};
</script>

<style lang="scss">
@import "./styles/base.scss";

#main {
  padding-left: $main-margin-x;
  padding-right: $main-margin-x;
  padding-bottom: 50px;
}
</style>
