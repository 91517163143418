<template>
  <div class="narrow-view">
    <h1>Log out</h1>
    <div v-if="error" class="error-box">{{ error }}</div>
    <form @submit.prevent="submit">
      <div class="group">Really log out?</div>
      <div class="group">
        <button class="submit" type="submit" :disabled="requestActive">Log out</button>
      </div>
    </form>
  </div>
</template>

<script>
import apiUtils from "@/services/api-utils";
import authService from "@/services/auth-service";

export default {
  name: "LogoutView",
  metaInfo: {
    title: "Logout",
  },
  data() {
    return {
      error: null,
      requestActive: false,
    };
  },
  methods: {
    async submit() {
      if (!this.requestActive) {
        this.requestActive = true;
        this.error = "";
        try {
          await authService.logout();
          await this.$router.push("/login");
        } catch (e) {
          const apiError = apiUtils.extractApiError(e);
          this.error = `Unable to log in: ${apiError.error}`;
        } finally {
          this.requestActive = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/base.scss";

form {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 20px;
  width: 100%;
}

.group {
  grid-column: 1 / 2;

  div {
    margin-bottom: 10px;
  }
}
</style>
