<template>
  <div class="wider-view">
    <h1>Manage task lists</h1>
    <div v-if="error" class="error-box">{{ error }}</div>
    <div v-if="loaded">
      <table v-if="taskLists.length">
        <tr>
          <th>Name</th>
          <th>Position</th>
          <th>Type</th>
          <th>Enabled</th>
          <th>Options</th>
        </tr>
        <tr v-for="taskList in taskLists" :id="taskList.id" :key="taskList.key" class="hoverable">
          <td>{{ taskList.name }}</td>
          <td>{{ taskList.position }}</td>
          <td>{{ taskList.type }}</td>
          <td>{{ taskList.enabled ? "Yes" : "No" }}</td>
          <td>
            <span class="option">
              <router-link :to="{ name: 'editTaskList', params: { taskListId: taskList.id } }">Edit</router-link>
            </span>
            <span class="option">
              <router-link :to="{ name: 'deleteTaskList', params: { taskListId: taskList.id } }">Delete</router-link>
            </span>
          </td>
        </tr>
      </table>
      <div v-else>No task lists exist.</div>
      <div class="pad-top right">
        <button class="submit" type="submit" @click.prevent="newTaskList">Create new task list</button>
      </div>
    </div>
  </div>
</template>

<script>
import apiUtils from "@/services/api-utils";
import taskService from "@/services/task-service";

export default {
  name: "ManageTaskListsView",
  metaInfo: {
    title: "Manage task lists",
  },
  data() {
    return {
      taskLists: [],
      error: "",
      loaded: false,
    };
  },
  methods: {
    async newTaskList() {
      await this.$router.push("/task-lists/new");
    },
  },
  async created() {
    try {
      this.taskLists = await taskService.getTaskLists();
      this.loaded = true;
    } catch (e) {
      const apiError = apiUtils.extractApiError(e);
      this.error = "Unable to load task lists: " + apiError.error;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/base.scss";

table {
  box-sizing: border-box;
  border-collapse: collapse;
  width: 100%;
}

tr {
  border-bottom: 1px solid #ffffff;
}

th,
td {
  text-align: left;
  padding: 8px 50px 8px 8px;
}

th {
  border-bottom: 1px solid #888888;
}

.option {
  margin-right: 15px;
}
</style>
