import axios from "axios";
import authService, { PATH_LOGIN } from "@/services/auth-service";
import router from "@/router";

const CSRF_HEADER_NAME = "x-csrf-header";
const CSRF_HEADER_TOKEN = "x-csrf-token";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

if (process.env.NODE_ENV === "development" && process.env.VUE_APP_HARDCODED_AUTH) {
  const header = process.env.VUE_APP_HARDCODED_AUTH;
  console.log("Using hard-coded authorization header: " + header);
  apiClient.defaults.headers.common["Authorization"] = header;
}

apiClient.defaults.withCredentials = true;

apiClient.interceptors.response.use(
  (response) => {
    const csrfToken = response.headers[CSRF_HEADER_TOKEN];
    if (csrfToken) {
      apiClient.defaults.headers.common[response.headers[CSRF_HEADER_NAME]] = csrfToken;
    }
    return response;
  },
  (error) => {
    if (error.response && error.config.url !== PATH_LOGIN && error.response.status === 401) {
      authService.clearLoggedInUser();
      return router.push("/login");
    } else {
      console.log(error);
      return Promise.reject(error);
    }
  }
);

export default apiClient;
