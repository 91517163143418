<template>
  <div class="narrow-view">
    <h1>Log in</h1>
    <div v-if="error" class="error-box">{{ error }}</div>
    <form @submit.prevent="submit">
      <div class="group">
        <div>
          <label for="username">Username</label>
        </div>
        <div>
          <input
            id="username"
            ref="username-input"
            type="text"
            v-model.trim="username"
            class="text-input-full-width"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="group">
        <div>
          <label for="password">Password</label>
        </div>
        <div>
          <input id="password" type="password" v-model="password" class="text-input-full-width" autocomplete="off" />
        </div>
      </div>
      <div class="group">
        <button class="submit" type="submit" :disabled="requestActive">Log in</button>
      </div>
    </form>
  </div>
</template>

<script>
import apiUtils from "@/services/api-utils";
import authService from "@/services/auth-service";

export default {
  name: "LoginView",
  metaInfo: {
    title: "Login",
  },
  data() {
    return {
      username: null,
      password: null,
      error: null,
      requestActive: false,
    };
  },
  methods: {
    async submit() {
      if (!this.requestActive) {
        this.requestActive = true;
        this.error = "";
        try {
          await authService.fetchCsrfToken();
          await authService.login(this.username, this.password);
          await this.$router.push({ name: "taskLists" });
        } catch (e) {
          const apiError = apiUtils.extractApiError(e);
          if (apiError.status === 401) {
            authService.clearLoggedInUser();
            this.error = "Log in failed";
            this.reset();
          } else if (apiError.hasFieldErrors) {
            this.fieldErrors = apiError.fieldErrors;
          } else {
            this.error = `Unable to log in: ${apiError.error}`;
          }
        } finally {
          this.requestActive = false;
        }
      }
    },
    reset() {
      this.username = "";
      this.password = "";
      this.$refs["username-input"].focus();
    },
  },
  created() {
    authService.clearLoggedInUser();
  },
  mounted() {
    this.$refs["username-input"].focus();
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/base.scss";

form {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 20px;
  width: 100%;
}

.group {
  grid-column: 1 / 2;

  div {
    margin-bottom: 10px;
  }
}
</style>
