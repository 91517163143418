<template>
  <div v-if="error" class="error-box">{{ error }}</div>
</template>

<script>
import apiUtils from "@/services/api-utils";

export default {
  name: "TaskListsView",
  metaInfo: {
    title: "Task lists",
  },
  data() {
    return {
      error: null,
    };
  },
  async created() {
    try {
      const summaries = await this.$store.dispatch("loadTaskListSummaries");

      if (!summaries || !summaries.length) {
        await this.$router.push({ name: "newTaskList" });
      } else {
        let activeTaskListId = this.$store.state.activeTaskListId;

        if (!activeTaskListId || !summaries.find((summary) => summary.id === activeTaskListId)) {
          activeTaskListId = summaries[0].id;
        }

        await this.$router.push({ name: "taskList", params: { taskListId: "" + activeTaskListId } });
      }
    } catch (e) {
      const apiError = apiUtils.extractApiError(e);
      this.error = "Unable to load task lists: " + apiError.error;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/base.scss";

.error-box {
  margin-top: 20px;
}
</style>
