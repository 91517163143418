import Vue from "vue";
import VueMeta from "vue-meta";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import linkify from "vue-linkify";

Vue.config.productionTip = false;

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.directive("linkified", linkify);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
