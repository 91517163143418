<template>
  <div v-if="tasks.length">
    <table class="tasks">
      <TaskRow v-for="task in tasks" :key="task.id" :id="task.id" :task="task" :task-list-type="taskList.type" />
    </table>
    <div class="bottom-options">
      <button class="submit" type="submit" :disabled="!completedTaskCount" @click.prevent="removeCompleted">
        Remove completed
      </button>
    </div>
  </div>
</template>

<script>
import TaskRow from "@/components/TaskRow";

export default {
  name: "TaskTable",
  components: { TaskRow },
  props: {
    taskList: Object,
  },
  data() {
    return {
      requestActive: false,
    };
  },
  methods: {
    async removeCompleted() {
      if (!this.requestActive) {
        this.requestActive = true;
        try {
          await this.$store.dispatch("removeCompleted");
        } catch (e) {
          // Show an error?
        } finally {
          this.requestActive = false;
        }
      }
    },
  },
  computed: {
    tasks() {
      return this.$store.getters.tasks;
    },
    completedTaskCount() {
      return this.$store.getters.completedTaskCount;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/base.scss";

.tasks {
  margin: 0 0 10px 0;
  border-collapse: collapse;
  width: 100%;
  box-sizing: border-box;
}

.bottom-options {
  margin-top: 15px;
  width: 100%;
  text-align: right;
}
</style>
